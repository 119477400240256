import React, { lazy } from "react";
import FallbackLoader from "../components/FallbackLoader";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

// import SubSubCategories from "./Category/SubSubCategories";
// import SubCategories from "./Category/SubCategories";
// import ProductListing from "./Product/ProductListing";

const HomeRoutes = lazy(() => import("./Home/Home"));
const AboutRoute = lazy(() => import("./About/About"));
const ProductDetail = lazy(() => import("./Product/ProductDetail"));
const NewArrivalRoute = lazy(() => import("./New Arrival/NewArrival"));
const ContactRoute = lazy(() => import("./Contact/Contact"));
const LoginRoute = lazy(() => import("./Login/Login"));
const SignupRoute = lazy(() => import("./Signup/Signup"));
const FeedbackRoute = lazy(() => import("./Feedback/Feedback"));
const CustomOrderRoute = lazy(() => import("./CustomOrder/CustomOrder"));
const ProductListingRoute = lazy(() => import("./Product/ProductListing"));
const SubCategoriesRoute = lazy(() => import("./Category/SubCategories"));
const SubSubCategoriesRoute = lazy(() => import("./Category/SubSubCategories"));
const SearchProductRoute = lazy(() => import("./Search/Search"));
const StorelocatorRoute = lazy(() => import("./StoreLocator/StoreLocator"));
const BookingSummaryRoute = lazy(() => import("./Booking/BookingSummary"));
const BookingDetailsRoute = lazy(() => import("./Booking/BookingDetails"));
const TrackOrdersRoute = lazy(() => import("./Track/TrackOrders"));
const TrackDetailsRoute = lazy(() => import("./Track/TrackingDetails"));
const PrivacyPolicyRoute = lazy(() => import("./Policies/PrivacyPolicy"));
const TermsandConditionRoute = lazy(() =>
  import("./Policies/TermsandCondition")
);
const RefundRoute = lazy(() => import("./Policies/Refund"));
const ReturnRoute = lazy(() => import("./Policies/Return"));
const ShippingRoute = lazy(() => import("./Policies/Shipping"));
const CartRoute = lazy(() => import("./Cart/Cart"));
const ProfileRoute = lazy(() => import("./Profile/Profile"));
const BulkOrderRoute = lazy(() => import("./BulkOrder/BulkOrder"));

const PageNotFoundRoute = lazy(() => import("../components/PageNotFound"));

export const DashboardRoute = {
  home: {
    key: "home",
    paths: ["/", "/home"],
    element: <HomeRoutes />,
    protected: "neutral",
    docTitle: "Home",
    suspense: true,
    fallback: <FallbackLoader />,
    icon: <HomeRoundedIcon />,
    label: "Home",
  },
  newArival: {
    key: "newArrival",
    paths: ["/newArrival"],
    element: <NewArrivalRoute />,
    protected: "neutral",
    docTitle: "New Arrival ",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "New Arrival",
  },
  about: {
    key: "about",
    paths: ["/about"],
    element: <AboutRoute />,
    protected: "neutral",
    docTitle: "About Us",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "About Us",
  },
  contact: {
    key: "contact",
    paths: ["/contact"],
    element: <ContactRoute />,
    protected: "neutral",
    docTitle: "Contact",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "Contact",
  },
  login: {
    key: "login",
    paths: ["/login"],
    element: <LoginRoute />,
    protected: false,
    docTitle: "Login Page",
    suspense: true,
    fallback: <FallbackLoader />,
    // component: </>
  },
  signup: {
    key: "signup",
    paths: ["/signup"],
    element: <SignupRoute />,
    protected: false,
    docTitle: "Signup Page",
    suspense: true,
    fallback: <FallbackLoader />,
    // component: </>
  },
  feedback: {
    key: "feedback",
    paths: ["/feedback"],
    element: <FeedbackRoute />,
    protected: true,
    docTitle: "Feedback Page",
    suspense: true,
    fallback: <FallbackLoader />,
    // component: </>
  },
  customOrder: {
    key: "customOrder",
    paths: ["/custom-order"],
    element: <CustomOrderRoute />,
    protected: true,
    docTitle: "Custom Order Page",
    suspense: true,
    fallback: <FallbackLoader />,
    // component: </>
  },
  // cart: {
  //   key: "cart",
  //   paths: ["/cart"],
  //   element: <CartRoute />,
  //   protected: true,
  //   docTitle: "Cart Page",
  //   suspense: true,
  //   fallback: <FallbackLoader />,
  //   // component: </>
  // },
  subCategory: {
    key: "subcategory",
    paths: ["/:subcategory"],
    element: <SubCategoriesRoute />,
    protected: "neutral",
    docTitle: "",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "subcategory",
  },
  subSubCategory: {
    key: "subsubcategory",
    paths: ["/:subcategory/:subsubcategory"],
    element: <SubSubCategoriesRoute />,
    protected: "neutral",
    docTitle: "",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "subsubcategory",
  },
  productListing: {
    key: "productListing",
    paths: ["/:subcategory/:subsubcategory/:plp"],
    element: <ProductListingRoute />,
    protected: "neutral",
    docTitle: "Product Listing",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "productListing",
  },
  productDetail: {
    key: "productDetail",
    paths: ["/:subcategory/:subsubcategory/:plp/:product"],
    element: <ProductDetail />,
    protected: "neutral",
    docTitle: "Product Detail",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "ProductDetail",
  },
  searchProduct: {
    key: "searchProduct",
    paths: ["/search/:name"],
    element: <SearchProductRoute />,
    protected: "neutral",
    docTitle: "Search Product",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "SearchProduct",
  },
  storelocator: {
    key: "storelocator",
    paths: ["/storelocator"],
    element: <StorelocatorRoute />,
    protected: "neutral",
    docTitle: "Store locator",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "Storelocator",
  },
  bookingSummary: {
    key: "bookingSummary",
    paths: ["/bookingsummary"],
    element: <BookingSummaryRoute />,
    protected: true,
    docTitle: "Booking Summary",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "bookingSummary",
  },
  bookingDetails: {
    key: "bookingDetails",
    paths: ["/bookingdetails/:orderId"],
    element: <BookingDetailsRoute />,
    protected: true,
    docTitle: "Booking Details",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "bookingDetails",
  },
  trackOrders: {
    key: "trackOrders",
    paths: ["/trackorders"],
    element: <TrackOrdersRoute />,
    protected: true,
    docTitle: "Track Orders",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "trackOrders",
  },
  trackDetails: {
    key: "trackDetails",
    paths: ["/trackdetails/:orderId"],
    element: <TrackDetailsRoute />,
    protected: true,
    docTitle: "Track Details",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "trackOrders",
  },
  privacyPolicy: {
    key: "privacyPolicy",
    paths: ["/privacypolicy"],
    element: <PrivacyPolicyRoute />,
    protected: "neutral",
    docTitle: "Privacy Policy",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "privacyPolicy",
  },
  termsandCondition: {
    key: "termsandCondition",
    paths: ["/termsandcondition"],
    element: <TermsandConditionRoute />,
    protected: "neutral",
    docTitle: "Terms & Condition",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "termsandCondition",
  },
  refund: {
    key: "refund",
    paths: ["/refund"],
    element: <RefundRoute />,
    protected: "neutral",
    docTitle: "Refund & Cancellation",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "refund",
  },
  return: {
    key: "return",
    paths: ["/return"],
    element: <ReturnRoute />,
    protected: "neutral",
    docTitle: "Return Policy",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "return",
  },
  shipping: {
    key: "shipping",
    paths: ["/shipping"],
    element: <ShippingRoute />,
    protected: "neutral",
    docTitle: "Shipping Policy",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "shipping",
  },
  profile: {
    key: "profile",
    paths: ["/profile"],
    element: <ProfileRoute />,
    protected: "neutral",
    docTitle: "Profile",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "profile",
  },
};

export const DashboardRouteList = Object.entries(DashboardRoute);

export const UnProtectedRoute = {
  home: {
    key: "home",
    paths: ["/", "/home"],
    element: <HomeRoutes />,
    protected: true,
    docTitle: "Home",
    suspense: true,
    fallback: <FallbackLoader />,
    icon: <HomeRoundedIcon />,
    label: "Home",
  },
  contact: {
    key: "contact",
    paths: ["/contact"],
    element: <ContactRoute />,
    protected: true,
    docTitle: "Contact",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "Contact",
  },
  login: {
    key: "login",
    paths: ["/login"],
    element: <LoginRoute />,
    protected: false,
    docTitle: "Login Page",
    suspense: true,
    fallback: <FallbackLoader />,
    // component: </>
  },
  storelocator: {
    key: "storelocator",
    paths: ["/storelocator"],
    element: <StorelocatorRoute />,
    protected: "neutral",
    docTitle: "Store locator",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "Storelocator",
  },
  signup: {
    key: "signup",
    paths: ["/signup"],
    element: <SignupRoute />,
    protected: false,
    docTitle: "Signup Page",
    suspense: true,
    fallback: <FallbackLoader />,
    // component: </>
  },
  newArival: {
    key: "newArrival",
    paths: ["/newArrival"],
    element: <NewArrivalRoute />,
    protected: "neutral",
    docTitle: "New Arrival ",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "New Arrival",
  },
  about: {
    key: "about",
    paths: ["/about"],
    element: <AboutRoute />,
    protected: "neutral",
    docTitle: "About Us",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "About Us",
  },
  feedback: {
    key: "feedback",
    paths: ["/feedback"],
    element: <FeedbackRoute />,
    protected: true,
    docTitle: "Feedback Page",
    suspense: true,
    fallback: <FallbackLoader />,
    // component: </>
  },
  subCategory: {
    key: "subcategory",
    paths: ["/:subcategory"],
    element: <SubCategoriesRoute />,
    protected: "neutral",
    docTitle: "",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "subcategory",
  },
  subSubCategory: {
    key: "subsubcategory",
    paths: ["/:subcategory/:subsubcategory"],
    element: <SubSubCategoriesRoute />,
    protected: "neutral",
    docTitle: "",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "subsubcategory",
  },
  productListing: {
    key: "productListing",
    paths: ["/:subcategory/:subsubcategory/:plp"],
    element: <ProductListingRoute />,
    protected: "neutral",
    docTitle: "Product Listing",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "productListing",
  },
  productDetail: {
    key: "productDetail",
    paths: ["/:subcategory/:subsubcategory/:plp/:product"],
    element: <ProductDetail />,
    protected: "neutral",
    docTitle: "Product Detail",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "ProductDetail",
  },
  searchProduct: {
    key: "searchProduct",
    paths: ["/search/:name"],
    element: <SearchProductRoute />,
    protected: "neutral",
    docTitle: "Search Product",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "SearchProduct",
  },
  privacyPolicy: {
    key: "privacyPolicy",
    paths: ["/privacypolicy"],
    element: <PrivacyPolicyRoute />,
    protected: "neutral",
    docTitle: "Privacy Policy",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "privacyPolicy",
  },
  termsandCondition: {
    key: "termsandCondition",
    paths: ["/termsandcondition"],
    element: <TermsandConditionRoute />,
    protected: "neutral",
    docTitle: "Terms & Condition",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "termsandCondition",
  },
  refund: {
    key: "refund",
    paths: ["/refund"],
    element: <RefundRoute />,
    protected: "neutral",
    docTitle: "Refund & Cancellation",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "refund",
  },
  return: {
    key: "return",
    paths: ["/return"],
    element: <ReturnRoute />,
    protected: "neutral",
    docTitle: "Return Policy",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "return",
  },
  shipping: {
    key: "shipping",
    paths: ["/shipping"],
    element: <ShippingRoute />,
    protected: "neutral",
    docTitle: "Shipping Policy",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "shipping",
  },
  // pageNotFound: {
  //   key: "pageNotFound",
  //   paths: ["/:pageNotFound/nf"],
  //   element: <PageNotFoundRoute />,
  //   protected: false,
  //   docTitle: "Page Not Found",
  //   suspense: true,
  //   fallback: <FallbackLoader />,
  //   label: "pageNotFound",
  // },
};
export const UnProtectedRouteList = Object.entries(UnProtectedRoute);

export const ProtectedRoute = {
  cart: {
    key: "cart",
    paths: ["/cart"],
    element: <CartRoute />,
    protected: true,
    docTitle: "Cart Page",
    suspense: true,
    fallback: <FallbackLoader />,
    // component: </>
  },
  customOrder: {
    key: "customOrder",
    paths: ["/custom-order"],
    element: <CustomOrderRoute />,
    protected: true,
    docTitle: "Custom Order Page",
    suspense: true,
    fallback: <FallbackLoader />,
    // component: </>
  },
  bulkOrder: {
    key: "bulkOrder",
    paths: ["/bulk-order"],
    element: <BulkOrderRoute />,
    protected: true,
    docTitle: "Bulk Order Page",
    suspense: true,
    fallback: <FallbackLoader />,
    // component: </>
  },
  bookingSummary: {
    key: "bookingSummary",
    paths: ["/bookingsummary"],
    element: <BookingSummaryRoute />,
    protected: true,
    docTitle: "Booking Summary",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "bookingSummary",
  },
  bookingDetails: {
    key: "bookingDetails",
    paths: ["/bookingdetails/:orderId"],
    element: <BookingDetailsRoute />,
    protected: true,
    docTitle: "Booking Details",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "bookingDetails",
  },
  trackOrders: {
    key: "trackOrders",
    paths: ["/trackorders"],
    element: <TrackOrdersRoute />,
    protected: true,
    docTitle: "Track Orders",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "trackOrders",
  },
  trackDetails: {
    key: "trackDetails",
    paths: ["/trackdetails/:orderId"],
    element: <TrackDetailsRoute />,
    protected: true,
    docTitle: "Track Details",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "trackOrders",
  },
  profile: {
    key: "profile",
    paths: ["/profile"],
    element: <ProfileRoute />,
    protected: "neutral",
    docTitle: "Profile",
    suspense: true,
    fallback: <FallbackLoader />,
    label: "profile",
  },
};
export const ProtectedRouteList = Object.entries(ProtectedRoute);

const routes = {
  ...DashboardRoute,
  ...ProtectedRoute,
  ...UnProtectedRoute,
};
export const routesList = [...ProtectedRouteList, ...UnProtectedRouteList];

export default routes;
