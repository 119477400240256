import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import logo from "../assets/icons/navbar/logo.png";
import CopyrightIcon from "@mui/icons-material/Copyright";
import faceBook from "../assets/icons/product/Vector.svg";
import instagram from "../assets/icons/product/instagram 1.svg";
import google from "../assets/icons/product/google 1.svg";
import meta from "../assets/icons/product/twitter 1.svg";
import pinterest from "../assets/icons/product/pinterest.svg";
import whatsapp from "../assets/icons/product/whatsapp.svg";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      bgcolor={"#F3F6F9"}
      sx={{
        padding: { xs: 3, lg: 6 },
      }}
    >
      <Grid container spacing={2} px={{ xs: 0, lg: 4 }}>
        <Grid item sm={6} md={3} xs={12} p={4} flex={1}>
          <Box
            onClick={() => navigate("/")}
            sx={{
              display: { sm: "block" },
              marginRight: { lg: 10, sm: 5 },
              cursor: "pointer",
            }}
          >
            <img src={logo} alt="" height={60} />
          </Box>
          <Box
            mt={3}
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
              gap: "0.5rem",
              flexWrap: "wrap",
            }}
          >
            <Link to="https://www.facebook.com/jagscraft" target="_blank">
              <Button
                sx={{
                  borderRadius: 50,
                  backgroundColor: "#D9E7F5",
                  aspectRatio: 1,
                  minWidth: "2.5rem",
                }}
              >
                <img src={faceBook} style={{ width: "60%" }} />
              </Button>
            </Link>
            <Link
              to="https://www.instagram.com/jagsindia_jagscraft/"
              target="_blank"
            >
              <Button
                sx={{
                  borderRadius: 50,
                  backgroundColor: "#D9E7F5",
                  aspectRatio: 1,
                  minWidth: "2.3rem",
                }}
              >
                <img src={instagram} style={{ width: "80%" }} />
              </Button>
            </Link>
            <Link to="https://twitter.com/jagsgifts?s=08" target="_blank">
              <Button
                sx={{
                  borderRadius: 50,
                  backgroundColor: "#D9E7F5",
                  aspectRatio: 1,
                  minWidth: "2.3rem",
                }}
              >
                <img src={meta} style={{ width: "80%" }} />
              </Button>
            </Link>
            <Link to="https://in.pinterest.com/jagsindia/" target="_blank">
              <Button
                sx={{
                  borderRadius: 50,
                  backgroundColor: "#D9E7F5",
                  aspectRatio: 1,
                  minWidth: "2.3rem",
                }}
              >
                <img src={pinterest} style={{ width: "80%" }} />
              </Button>
            </Link>
            <Link
              to="https://www.google.com/maps/place/Jai+Ambe+Gifts+%26+Stationery/@18.9505163,72.8327727,15z/data=!4m5!3m4!1s0x0:0x7059aea9c5d943b7!8m2!3d18.9505163!4d72.8327727?shorturl=1"
              target="_blank"
            >
              <Button
                sx={{
                  borderRadius: 50,
                  backgroundColor: "#D9E7F5",
                  aspectRatio: 1,
                  minWidth: "2.3rem",
                }}
              >
                <img src={google} style={{ width: "80%" }} />
              </Button>
            </Link>
          </Box>
        </Grid>
        <Grid item md={3} sm={6} xs={12} flex={1} alignContent={"start"}>
          <Typography
            variant="h5"
            // component="div"
            sx={{
              color: "#465152",
              fontWeight: 700,
              marginBottom: 1,
              height: "55px",
            }}
          >
            Find by Categories
          </Typography>
          <Typography
            my={3}
            onClick={() => navigate("/2983-Art & Craft")}
            sx={{
              color: "#465152",
              cursor: "pointer",
              fontWeight: "500",
              width: "fit-content",
              "&:hover": {
                color: "#EE7F1C",
              },
            }}
          >
            Art & Craft
          </Typography>
          <Typography
            my={3}
            onClick={() => navigate("/2984-Corporate & Office Stationery")}
            sx={{
              color: "#465152",
              cursor: "pointer",
              fontWeight: "500",
              width: "fit-content",
              "&:hover": {
                color: "#EE7F1C",
              },
            }}
          >
            Corporate & Office Stationery
          </Typography>
          <Typography
            my={3}
            onClick={() => navigate("/2985-Return Gift & More")}
            sx={{
              color: "#465152",
              cursor: "pointer",
              fontWeight: "500",
              width: "fit-content",
              "&:hover": {
                color: "#EE7F1C",
              },
            }}
          >
            School Stationery & Return Gift
          </Typography>
        </Grid>
        <Grid item md={2} sm={6} xs={12} flex={1}>
          <Typography
            variant="h5"
            // component="div"
            sx={{
              color: "#465152",
              fontWeight: 700,
              marginBottom: 1,
              height: "55px",
            }}
          >
            Customer Care
          </Typography>
          <Typography
            my={3}
            onClick={() => navigate("/contact")}
            sx={{
              color: "#465152",
              cursor: "pointer",
              fontWeight: "500",
              width: "fit-content",
              "&:hover": {
                color: "#EE7F1C",
              },
            }}
          >
            Contact Us
          </Typography>
          <Typography
            my={3}
            onClick={() => navigate("/storelocator")}
            sx={{
              color: "#465152",
              cursor: "pointer",
              fontWeight: "500",
              width: "fit-content",
              "&:hover": {
                color: "#EE7F1C",
              },
            }}
          >
            Store Locator
          </Typography>
        </Grid>
        <Grid item md={2} sm={6} xs={12} flex={1}>
          <Typography
            variant="h5"
            // component="div"
            sx={{
              color: "#465152",
              fontWeight: 700,
              marginBottom: 1,
              height: "55px",
            }}
          >
            Policies
          </Typography>
          <Typography
            my={2}
            onClick={() => navigate("/termsandcondition")}
            sx={{
              color: "#465152",
              cursor: "pointer",
              fontWeight: "500",
              width: "fit-content",
              "&:hover": {
                color: "#EE7F1C",
              },
            }}
          >
            Terms & Conditions
          </Typography>
          <Typography
            my={2}
            onClick={() => navigate("/privacypolicy")}
            sx={{
              color: "#465152",
              cursor: "pointer",
              fontWeight: "500",
              width: "fit-content",
              "&:hover": {
                color: "#EE7F1C",
              },
            }}
          >
            Privacy Policy
          </Typography>

          <Typography
            my={2}
            onClick={() => navigate("/refund")}
            sx={{
              color: "#465152",
              cursor: "pointer",
              fontWeight: "500",
              width: "fit-content",
              "&:hover": {
                color: "#EE7F1C",
              },
            }}
          >
            Refund & Cancellation
          </Typography>
          <Typography
            my={2}
            onClick={() => navigate("/return")}
            sx={{
              color: "#465152",
              cursor: "pointer",
              fontWeight: "500",
              width: "fit-content",
              "&:hover": {
                color: "#EE7F1C",
              },
            }}
          >
            Return Policy
          </Typography>
          <Typography
            my={2}
            onClick={() => navigate("/shipping")}
            sx={{
              color: "#465152",
              cursor: "pointer",
              fontWeight: "500",
              width: "fit-content",
              "&:hover": {
                color: "#EE7F1C",
              },
            }}
          >
            Shipping Policy
          </Typography>
        </Grid>
        <Grid item md={2} sm={6} xs={12} flex={1}>
          <Typography
            variant="h5"
            // component="div"
            sx={{
              color: "#465152",
              fontWeight: 700,
              marginBottom: 1,
              height: "55px",
            }}
          >
            Call Us
          </Typography>
          <Typography
            my={3}
            sx={{
              color: "#465152",
              cursor: "pointer",
              fontWeight: "500",
              width: "fit-content",
            }}
          >
            (+91) 98204 82480 <br /> Jai Ambe Gifts & Stationery (JAGS) Shop No.
            1 & 2, 172, Abdul Rehman St, near Zaveri Bazar, Chippi Chawl,
            Kalbadevi, Mumbai, Maharashtra 400003
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        px={{ xs: 0, md: 4 }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Typography
            my={3}
            sx={{
              color: "#465152",
              fontWeight: "500",
              width: "fit-content",
              display: "flex",
              alignItems: "end",
            }}
          >
            All Rights Reserved
            <CopyrightIcon
              fontSize="small"
              sx={{ marginX: { xs: "0px", md: "2px" }, fontWeight: "400" }}
            />
            Jags India 2023
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          display={"flex"}
          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
        >
          <Typography
            my={3}
            sx={{
              color: "#465152",
              fontWeight: "500",
              width: "fit-content",
            }}
          >
            Powered by{" "}
            <span style={{ color: "#EE7F1C" }}>
              <a
                href="https://dwebbox.com/"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                D-WebBox
              </a>
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
